<template>
  <div class="container">
    <div class="w-full border bg-gray-200 rounded-md  mb-8">
      <div class="grid grid-cols-6 gap-4 p-8">
        <div class="col-span-2">
          <div class="flex items-baseline text-3xl">
            <i class="mdi mdi-moped-outline text"></i>
            <h2>Dettagli Acquisto</h2>
          </div>
          <p>
            <b
              >{{ currentUserData.attributes.name }}
              {{ currentUserData.attributes.family_name }}</b
            ><br />
            Piazza Leonardo da Vinci<br />
            70123 - Bari (Ba)
          </p>
        </div>
        <div class="col-span-4">
          <ul class="text-sm">
            <li class="flex" v-for="(item, i) in items" :key="i">
              <span class="flex-grow">{{ item.product_name }}</span>
              <span class="nowrap"
                >{{ item.quantity }} x
                <b>{{ item.single_unit_price | price }}</b></span
              >
            </li>
            <li class="flex text-xl border-t pt-2">
              <span class="flex-grow">Totale</span>
              <b class="">{{ cartTotal | price }}</b>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-center w-full">
      <div
        id="remoney___btn"
        data-apiKey="uWZ6kOK0Z9aOtPrpk3y0k5bpPcrkWOaP2kzjpCYi"
        :data-items="remoneyJsonCart"
        :data-recipient="remoneyCart.recipient_code"
        :data-amount="remoneyCart.amount"
        data-callbackUrl="https://mysite/transaction-status"
        ref="paywit"
      ></div>
    </div>
    <div class="w-full bg-gray-100 py-12 mt-12">
      <div class="mt-6 pt-12 border-t grid grid-cols-12 w-2/3 mx-auto">
        <div class="col-span-6 text-center">
          <img src="@/_assets/images/wallet.png" />
        </div>
        <div class="col-span-6">
          <img src="@/_assets/images/remoney_logo.png" />
          <h1 class="text-4xl font-medium mt-8">Come Funziona?</h1>
          <p class="text-gray-600">
            Re·money è un sistema di pagamento digitale basato sulla logica
            cashless. Crea il tuo wallet digitale, paga tramite device e ottieni
            a ogni acquisto cashback da utilizzare in tutti i punti vendita
            aderenti.
          </p>
          <div class="flex mt-12">
            <a class="cursor-pointer">
              <img src="@/_assets/images/apple_store.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: ["remoneyCart"],
  data() {
    return {
      cart: null
    };
  },
  mounted() {
    console.log("Add Remoney");
    this.$emit("addRemoney");
    //this.cart = { ...this.remoneyCart };
  },
  computed: {
    ...mapState("cart", ["items"]),
    ...mapGetters("cart", ["cartTotal"]),
    ...mapGetters("auth", ["currentUserData"]),
    remoneyJsonCart() {
      return JSON.stringify(this.remoneyCart.json_cart);
    }
  },
  methods: {}
};
</script>

<style></style>
